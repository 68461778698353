import React, { useState } from "react";
import { ExpandMore } from "@material-ui/icons";
import Dropdown from "../../components/ReallyCustomDropDown";
import Select from '../Select';
import Checkbox from "../Checkbox";
import clsx from "clsx";
import { isArray } from "lodash";

const TagsDisplay = ({ value: values = [], options }) => {
  return (
    <div className="flex flex-wrap">
      {values.map(value => {
        const option = options.find(({value: optionValue}) => optionValue == value);
        return (
          <span
            key={value}
            className="bg-gray-100 rounded px-2 mr-1.5 my-1"
          >
            {option?.label}
          </span>
        )
      })}
    </div>
  );
};

const SimpleDisplay = ({ value, options })  => {
  const option = options.find(({value: optionValue}) => optionValue == value)
  return (
    <span className="text-lg">
      {option?.label}
    </span>
  );
};

const DropdownSelect = ({
  options = [],
  value,
  onChange,
  multiple = false,
  placeholder,
  className,
  placement,
  disabled = false,
  ...props
}) => {

  const [open, setOpen] = useState(false)

  const menu = (
    <div className="bg-white border shadow-md rounded-md mt-2 py-2 divide-y z-50 max-h-52 overflow-y-auto w-full">
      <Select
        value={value}
        multiple={multiple}
        onChange={e => {
          onChange(e)
          if(!multiple) setOpen(false)
        }}
      >
        {isArray(options) && options.map(({value, label}) => (
          <Select.Option
            key={value}
            value={value}
          >
            {props => (
              <div
                className="hover:bg-gray-100 cursor-pointer px-4 py-1.5 text-lg flex items-center select-none whitespace-nowrap"
                {...props}
              >
                {multiple && (
                  <Checkbox
                    checked={props.checked}
                    className="mr-2"
                  />
                )}
                {label}
              </div>
            )}
          </Select.Option>
        ))}
      </Select>
    </div>
  )

  const DisplayValue = multiple ? TagsDisplay : SimpleDisplay;

  const triggerClassName = clsx(
    'report-form-control cursor-pointer flex items-center px-2 py-1.5',
    disabled ? 'opacity-60 pointer-events-none' : null
  );

  return (
    <Dropdown
      overlay={menu}
      open={open}
      onOpenChange={setOpen}
      placement={placement}
      className={className}
      disabled={disabled}
    >
      <div
        className={triggerClassName}
        {...props}
      >
        <div className="flex-1">
          {(value && value.length) ? (
            <DisplayValue
              value={value}
              options={options}
            />
          ) : (
            <span className="opacity-40 text-lg mr-1.5">
              {placeholder}
            </span>
          )}
        </div>
        <ExpandMore className="ml-1.5" />
      </div>
    </Dropdown>
  );
};

export default DropdownSelect;